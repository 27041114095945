import TransitionFadeExpandOnLeave from '@/transitions/fade-expand-on-leave/fade-expand-on-leave.vue'
import layout from '@/layouts/layout-public.vue';
import button from '@/components/button/button.vue';
import footer from '@/components/footer/footer.vue';
import modal from '@/components/modal/modal.vue';

export default {
  name: 'index',
  layout: 'layout-public',
  components: {
    'transition-fade-expand-on-leave': TransitionFadeExpandOnLeave,
    'l-layout': layout,
    'c-button': button,
    'c-footer': footer,
    'c-modal': modal
  },
  data() {
    return {
      modalOrdersVisible: false,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        grabCursor: false,
        simulateTouch: false
      },
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  },
  methods: {
    setModalHeight() {
      // eslint-disable-next-line no-console
      this.$refs.modalContent.style.height = 'initial'
      if (this.$refs.modalContent) {
        this.$refs.modalContent.style.height = document.getElementById('modal__window').clientHeight + 'px'
      }
    }
  },
  mounted() {
    const _this = this
    setTimeout(function() {
      _this.setModalHeight()
    }, 25)

    window.addEventListener('resize', this.setModalHeight)
  }
};
