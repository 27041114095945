import { TimelineMax, Power1 } from 'gsap/dist/gsap'

// eslint-disable-next-line no-unused-vars
const plugins = [TimelineMax, Power1]

export default {
  name: 'TransitionFadeExpandOnLeave',
  props: {
    name: 'fade-expand-on-leave',
    durationIn: {
      type: Number,
      default: 0.3
    },
    durationOut: {
      type: Number,
      default: 0.15
    }
  },
  methods: {
    enter(element, done) {
      const tl = new TimelineMax()
      tl.add('enter')
      tl.fromTo(
        element,
        this.durationIn,
        {
          opacity: 0
        },
        {
          opacity: 1,
          ease: Power1.easeOut,
          onComplete() {
            done()
          }
        },
        'enter'
      )
    },
    afterEnter() {},
    leave(element, done) {
      const tl = new TimelineMax()
      tl.add('leave')
      tl.fromTo(
        element,
        this.durationOut,
        {
          opacity: 1,
          scale: 1
        },
        {
          opacity: 0,
          scale: 1.1,
          ease: Power1.easeOut,
          onComplete() {
            done()
          }
        },
        'leave'
      )
    }
  }
}
