import iconClose from '@/components/icons/close.vue';

export default {
  name: 'Modal',

  components: {
    'c-icon-close': iconClose
  },

  data() {
    return {
      closeVisible: true
    }
  },

  methods: {
    onClose() {
      const _this = this
      this.closeVisible = false
      document.body.classList.remove("no-scroll")
      this.$nextTick(() => {
        _this.$emit('close')
      })
    }
  },

  mounted() {
    document.body.classList.add("no-scroll")
  }
}
