import cIconFacebook from '@/components/icons/facebook.vue'
import cIconWhatsapp from '@/components/icons/whatsapp.vue'

export default {
  name: 'cButton',

  components: {
    'c-icon-facebook': cIconFacebook,
    'c-icon-whatsapp': cIconWhatsapp
  },

  props: {
    label: String,
    type: {
      type: String,
      default: 'button'
    },
    icon: String,
    iconPosition: String,
    showPreloader: {
      type: Boolean,
      default: false
    }
  }
}
