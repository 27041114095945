import cIconInstagram from '@/components/icons/instagram.vue';
import cIconFacebook from '@/components/icons/facebook.vue';

export default {
  name: 'Footer',
  components: {
    'c-icon-instagram': cIconInstagram,
    'c-icon-facebook': cIconFacebook
  }
};
