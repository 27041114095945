import cLogoMichelin from '@/components/logo-michelin/logo-michelin.vue';
import cIconInstagram from '@/components/icons/instagram.vue';
import cIconFacebook from '@/components/icons/facebook.vue';

export default {
  name: 'TopBar',
  components: {
    'c-logo-michelin': cLogoMichelin,
    'c-icon-instagram': cIconInstagram,
    'c-icon-facebook': cIconFacebook
  },
  computed: {
    locale() {
      return this.$i18n.locale
    }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
    }
  },
  mounted() {
    const scene = this.$scrollmagic.scene({
      triggerElement: '#ambiance',
      triggerHook: 0.8
    })
    .setClassToggle("#top-bar", "hidden")
    this.$scrollmagic.addScene(scene)
  }
};
