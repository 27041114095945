/* eslint-disable no-console */
import cTopBar from '@/components/top-bar/top-bar.vue';

export default {
  name: 'layout-public',
  components: {
    'c-top-bar': cTopBar
  },
  data() {
    return {
    }
  }
};
